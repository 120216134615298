<template>
  <div class="act-main">
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="formRef" label-width="120px">
          <el-form-item prop="sponsor" label="主办方">
            <el-input v-model="form.sponsor"></el-input>
          </el-form-item>
          <el-form-item label="活动说明" prop="note">
            <el-input type="textarea" rows="4" v-model="form.note" maxlength="100" placeholder="请输入活动说明"></el-input>
          </el-form-item>
          <el-form-item label="活动规则" prop="activity_rule">
              <el-input v-model="form.activity_rule" type="textarea" maxlength="100" rows="4" placeholder="请输入活动规则" />
          </el-form-item>
          <el-form-item label="活动开始时间" prop="begin_time">
            <el-date-picker v-model="form.begin_time" type="datetime" placeholder="活动开始时间" />
          </el-form-item>
          <el-form-item label="活动结束时间" prop="end_time">
            <el-date-picker v-model="form.end_time" type="datetime" placeholder="活动结束时间" />
          </el-form-item>
          <el-form-item label="积分" prop="give_point">
            <el-input-number v-model.number="form.give_point" />
          </el-form-item>
          <el-form-item>
              <el-button @click="resetForm(formRef)">重置</el-button>
              <el-button type="primary" @click="submitForm(formRef)">保存</el-button>
          </el-form-item>
        </el-form>
      </div>


  </div>
</template>

<script setup>
import {activityAdd,activityDetail} from "@/utils/apis";
import {onMounted, reactive, ref, toRefs} from "vue";
import {ElMessage} from "element-plus"
const formRef = ref()
const form = reactive({
    activity_rule: '', //活动规则
    sponsor: '',       //主板方
    begin_time: '',    //活动开始时间
    end_time: '',      //活动结束时间
    note: '',          //活动说明
    give_point: 0      //签到赠送积分
})
const rules = reactive({
  activity_rule: [
    {required: true, message: '活动规则不能为空', trigger: 'blur'},
    {min: 1, max: 100, message: '活动规则字数在1到100之间', trigger: 'blur'}
  ],
  sponsor: [
    {required: true, message: '主办方不能为空', trigger: 'blur'},
    {min: 1, max: 20, message: '主办方字数在1到20之间', trigger: 'blur'}
  ],
  begin_time: [
    {required: true, message: '活动开始时间不为空', trigger: 'blur'}
  ],
  end_time: [
    {required: true, message: '活动结束时间不为空', trigger: 'blur'}
  ],
  note: [
    {required: true, message: '活动说明不能为空', trigger: 'blur'},
    {min: 1, max: 100, message: '活动说明字数在1到100之间', trigger: 'blur'}
  ],
  give_point: [
    {required: true, message: '赠送积分不能为空', trigger: 'blur'}
  ]
})
const submitForm = async (formEle) => {
    if (!formEle) return
    await formEle.validate((valid, fields) => {
        if (valid) {
          form.begin_time = Date.parse(form.begin_time) / 1000
          form.end_time = Date.parse(form.end_time) / 1000
          saveActivity(form)
        } else {
            console.log('取消')
        }
    })
}
const saveActivity = async (form) => {
    try {
        const {data, msg} = await activityAdd(form)
        ElMessage({
            type: 'success',
            message: msg,
            duration: 1000,
            onClose: () => {
                activityInfo()
            }
        })
    } catch (e) {
        ElMessage.warning(e.message)
    }

}
const resetForm = (formEle) => {
    if (!formEle) return
    formEle.resetFields()
}
const activityInfo = async () => {
  try {
      const {data, msg} = await activityDetail()
      Object.assign(form, {...data})
  } catch (e) {
      ElMessage.warning(e.message)
  }
}

onMounted(() => {
  activityInfo()
})

</script>

<style scoped lang="scss">
.act-main {
   padding: 10px;
   &, & * {
    box-sizing: border-box;
  }
  .form-box {
      width: 500px;
  }
}
</style>